
html {
	width: 100%;
	height: 100%;
}

body {
	font-size: 16px;
	font-family: 'Comfortaa', sans-serif;
	color: black;

	#background {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.9;
		filter: brightness(1.3);
		background-image: url('../images/bg1_small.jpg');
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}

	@media (max-width: 768px) {
		font-size: 12px;
	}
	@media (max-height: 500px) {
		font-size: 12px;
	}

	#content {
		display: block;
		width: 60%;
		max-width: 400px;
		min-width: 260px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		
		#title {
			
			position: relative;
			font-size: 3em;
			font-weight: bold;

			&.active #never span {
				opacity: 0;
			}

			&.hover #heard span {
				color: white;
			}
			
			.line {
				white-space: nowrap;
				line-height: 1.1em;
				text-transform: uppercase;
				clear: both;


				span {
					float: left;
					white-space: pre;
					padding: 0px;
					margin: 0px;
					transition: opacity 0.2s, color 0.2s;;
				}
				
			}
			
			$delay-time: 0.01s;

			#never span {
				@for $i from 1 through 15 {
					&:nth-child(#{$i}) {
						transition-delay: $delay-time * (12 - $i);
					}
				}
				@for $i from 6 through 15 {
					&:nth-child(#{$i}) {
						text-shadow: -1px 1px 1px adjust-hue(#fde457, ($i - 6) * 6);
					}
				}
			}

			#heard span {
				@for $i from 1 through 13 {
					&:nth-child(#{$i}) {
						transition-delay: $delay-time * $i;
						text-shadow: -1px 1px 1px adjust-hue(#ff4bff, -($i - 1) * 12);
					}
				}
			}
			
		}


		button#sound {
			-webkit-appearance: none;
			border: none;
			width: 20%;
			background-color: transparent;
			text-align: center;
			position: absolute;
			right: 0px;
			top: 50%;
			transform: translate(0%, -50%);
			// outline-color: #57e6fd;
			outline: none;
			cursor: pointer;
			
			svg {
				transition: all 0.3s;
				height: auto;
				width: 100%;
			}
			
			&:hover svg {
				fill: #57e6fd;
			}
			
			&:active svg {
				filter: blur(4px);
			}
		}
	}

	#blurb {
		position: absolute;
		color: black;
		top: 10vh;
		left: 10vh;
		width: 50%;
		// max-width: 400px;
		// min-width: 200px;
		width: 300px;
		// font-size: 1em;
		font-size: 16px;
		line-height: 1.4em;
		font-style: italic;
		font-weight: lighter;
		text-align:left;
	}

	footer {
		position: absolute;
		color: black;
		bottom: 5vh;
		left: 0px;
		width: 100%;
		text-align:center;
		font-size: 1.0em;
		
		#mc_embed_signup {
			font-size: inherit;
			font-weight: bold;

			input {
				background-color: rgba(255, 255, 255, 0.5);
				font-family: inherit;
				font-size: inherit;
				border: none;
				padding: 5px 10px;
			}

			input.button {
				background-color: white;
				cursor: pointer;
			}

			input.email {
				width: 40%;
				max-width: 280px;
				min-width: 120px;
			}
		}

		div {
			font-weight: bold;
			font-size: 1.2em;
			line-height: 1.2em;
			margin-top: 30px;
		}

		a {
			// color: #008aff;
			color: black;
		}
	}
	
}